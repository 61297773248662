import classNames from 'classnames/bind';
import React from 'react';

import { Button } from '@components/Button/Button';
import { Icon } from '@components/Icon/Icon';
import { Col } from '@components/Layout/Col/Col';
import { Row } from '@components/Layout/Row/Row';
import { externalRoutes, routes } from '@routes';
import { sales, convertTelToHref } from '@siteData';

import styles from './TopBar.module.scss';
import PhoneIcon from '@icons/phone.svg';

const cx = classNames.bind(styles);

interface IProps {
  isVisibleOnMobile: boolean;
  onCloseMenu: () => void;
}

export const TopBar: React.FC<IProps> = ({ isVisibleOnMobile, onCloseMenu }) => (
  <div className={cx('wrapper', { isVisibleOnMobile })}>
    <div className={styles.container}>
      <Row gap="xs" horizontalAlign="between">
        <Col className={cx('row', 'phoneWrapper')} gap="xs" xs="auto">
          <Icon className={styles.phoneIcon} color="currentColor" opacity={50} size={12}>
            <PhoneIcon />
          </Icon>
          <Button
            className={styles.phone}
            href={convertTelToHref(sales.phone.uk)}
            variant="inherit"
          >
            {sales.phone.uk}
          </Button>
        </Col>
        <Col className={styles.linksWrapper} gap="xs" xs="auto">
          <Button
            className={styles.link}
            href={externalRoutes.client.support}
            variant="inherit"
            external
            noIcon
            onClick={() => {
              onCloseMenu();
            }}
          >
            Docs &amp; API
          </Button>
          <Button
            className={styles.link}
            href={routes.contact}
            variant="inherit"
            onClick={() => {
              onCloseMenu();
            }}
          >
            24/7 support
          </Button>
        </Col>
      </Row>
    </div>
  </div>
);
